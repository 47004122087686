import Vue from 'vue';

import XCombobox from './XCombobox.vue';
import XSelect from './XSelect.vue';
import XTextField from './XTextField.vue';
import XTimePicker from './XTimePicker.vue';

Vue.component('XCombobox', XCombobox);
Vue.component('XSelect', XSelect);
Vue.component('XTextField', XTextField);
Vue.component('XTimePicker', XTimePicker);
