<template>
  <VMenu v-model="menu" :close-on-content-click="false" offset-y min-width="120px">
    <template #activator="{ on, attrs }">
      <VTextField
        :class="classString"
        dense
        hide-details
        readonly
        :append-icon="outlined ? '' : 'mdi-menu-down'"
        :value="value"
        :rules="rules"
        :outlined="outlined"
        v-bind="attrs"
        v-on="on"
        @click:append="menu = !menu"
      />
    </template>
    <!-- FIXME: 暫時使用 style="margin-top: 1px" 修復卡片遮住輸入框底線1像素-->
    <VueCtkDateTimePicker
      style="margin-top: 1px"
      color="#44b272"
      no-button
      inline
      only-time
      format="HH:mm"
      :value="value"
      @input="$emit('input', $event)"
    />
  </VMenu>
</template>

<script>
import Vue from 'vue';

import { required } from '@/utils/inputRules';

export default Vue.extend({
  name: 'XTimePicker',
  props: {
    value: { validator: (v) => _.isNil(v) || _.isString(v), required: true },
    label: { type: String, default: '' },
    rules: { type: Array, default: () => [required] },
    outlined: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    classString() {
      const labelClass = this.outlined || this.label.trim().length > 0 ? '' : 'v-input--hide-label';
      const alignClass = 'text-center';
      const menuClass = this.menu ? 'v-select v-select--is-menu-active' : 'v-autocomplete';
      return `${labelClass} ${alignClass} ${menuClass}`;
    },
  },
});
</script>
