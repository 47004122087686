<template>
  <VApp>
    <VAppBar app color="primary" dark dense :flat="isSigned">
      <template #img>
        <VImg
          class="ma-2"
          :src="require('@/assets/images/logo.png')"
          contain
          width="332px"
          height="80px"
        />
      </template>
      <template #extension>
        <!-- TODO: override #next and #prev slot make only skip 1 item when pressing buttons -->
        <!-- FIXME: center-active is not working due to use :to to active, not v-slot active() -->
        <VSlideGroup multiple show-arrows style="padding-left: 332px">
          <template
            v-if="
              _.isString($route.name) &&
              !menuBarItems.map((v) => v.name === $route.name).includes(true)
            "
          >
            <VSlideItem>
              <VMenu
                v-if="$route.name.startsWith('hd-record-')"
                open-on-hover
                offset-y
                transition="slide-y-transition"
              >
                <template #activator="{ on, attrs }">
                  <VBtn
                    style="margin: 0 3px"
                    active-class="primary darken-3"
                    :input-value="true"
                    text
                    rounded
                    v-bind="attrs"
                    v-on="on"
                  >
                    透析紀錄
                  </VBtn>
                </template>
                <VList dense>
                  <VListItem
                    v-for="(item, j) in hdMenuItems"
                    :key="j"
                    :to="{ name: item.name, params: $route.params, query: $route.query }"
                  >
                    <VListItemTitle>{{ item.title }}</VListItemTitle>
                  </VListItem>
                </VList>
              </VMenu>
              <VMenu
                v-else-if="$route.name.startsWith('hd-assessment-')"
                open-on-hover
                offset-y
                transition="slide-y-transition"
              >
                <template #activator="{ on, attrs }">
                  <VBtn
                    style="margin: 0 3px"
                    active-class="primary darken-3"
                    :input-value="true"
                    text
                    rounded
                    v-bind="attrs"
                    v-on="on"
                  >
                    透析評估表單
                  </VBtn>
                </template>
                <VList dense>
                  <VListItem
                    v-for="(item, j) in hdMenuItems1"
                    :key="j"
                    :to="{ name: item.name, params: $route.params, query: $route.query }"
                  >
                    <VListItemTitle>{{ item.title }}</VListItemTitle>
                  </VListItem>
                </VList>
              </VMenu>
            </VSlideItem>
            <VDivider vertical light />
          </template>
          <VSlideItem v-for="(item, i) in menuBarItems" :key="i">
            <VMenu v-if="'items' in item" open-on-hover offset-y transition="slide-y-transition">
              <template #activator="{ on, attrs }">
                <VBtn
                  style="margin: 0 3px"
                  active-class="primary darken-3"
                  :input-value="$route.name === item.name"
                  text
                  rounded
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ item.title }}
                </VBtn>
              </template>
              <VList dense>
                <VListItem
                  v-for="(subItem, j) in item.items"
                  :key="j"
                  :to="{ name: item.name, params: { for: subItem.for } }"
                >
                  <VListItemTitle>{{ subItem.title }}</VListItemTitle>
                </VListItem>
              </VList>
            </VMenu>
            <VBtn
              v-else
              style="margin: 0 3px"
              active-class="primary darken-3"
              text
              rounded
              :to="{ name: item.name }"
            >
              {{ item.title }}
            </VBtn>
          </VSlideItem>
        </VSlideGroup>
      </template>
      <VSpacer style="padding-left: 332px" />
      <VToolbarTitle style="font-size: 28px" class="mt-auto">
        {{ $store.state.app.name }}
      </VToolbarTitle>
      <VSpacer />
      <VMenu
        max-width="240px"
        offset-y
        transition="slide-y-transition"
        :close-on-content-click="false"
      >
        <template #activator="{ on, attrs }">
          <VBtn
            text
            class="ml-1 pa-1"
            min-width="0"
            height="48"
            style="border-radius: 24px"
            v-bind="attrs"
            v-on="on"
          >
            <VAvatar size="40" color="#c5c5c5">
              <VImg :src="photoURL" :lazy-src="lazyPhotoURL" />
            </VAvatar>
            <span class="mx-1 font-weight-regular text-none" style="font-size: 1.125rem">
              {{ isSigned ? $store.state.profile.name : '未登入' }}
            </span>
          </VBtn>
        </template>
        <VCard v-if="isSigned">
          <VList>
            <VListItem>
              <VListItemAvatar color="#c5c5c5">
                <VImg :src="photoURL" :lazy-src="lazyPhotoURL" />
              </VListItemAvatar>
              <VListItemContent>
                <VListItemTitle>{{ $store.state.profile.name }}</VListItemTitle>
                <VListItemSubtitle>{{ $store.state.profile.email }}</VListItemSubtitle>
              </VListItemContent>
              <VListItemAction>
                <VBtn icon>
                  <VIcon>mdi-square-edit-outline</VIcon>
                </VBtn>
              </VListItemAction>
            </VListItem>
          </VList>
          <VDivider />
          <VCardActions>
            <VBtn color="pink" outlined text block @click="signOut()">
              <VIcon left>mdi-logout-variant</VIcon>
              登出
            </VBtn>
          </VCardActions>
        </VCard>
      </VMenu>
      <VMenu v-model="appMenuIsOpen" left offset-y transition="slide-y-transition">
        <template #activator="{ on, attrs }">
          <VBtn class="ml-1" v-bind="attrs" icon v-on="on">
            <VIcon>mdi-dots-vertical</VIcon>
          </VBtn>
        </template>
        <VList dense>
          <VListItem @click="$vuetify.theme.dark = !$vuetify.theme.dark">
            <VListItemIcon>
              <VIcon>mdi-{{ $vuetify.theme.dark ? 'weather-night' : 'white-balance-sunny' }}</VIcon>
            </VListItemIcon>
            <VListItemTitle>主題：{{ `${$vuetify.theme.dark ? '深色' : '淺色'}` }}</VListItemTitle>
          </VListItem>
          <AppAboutDialog
            v-model="aboutDialogIsOpen"
            @input="$event ? null : (appMenuIsOpen = false)"
          >
            <template #activator="{ on, attrs }">
              <VListItem v-bind="attrs" v-on="on">
                <VListItemIcon>
                  <VIcon>mdi-information</VIcon>
                </VListItemIcon>
                <VListItemTitle>關於</VListItemTitle>
              </VListItem>
            </template>
          </AppAboutDialog>
        </VList>
      </VMenu>
    </VAppBar>
    <VMain>
      <VFadeTransition mode="out-in">
        <RouterView v-if="isSigned" style="max-height: calc(100vh - 96px)" />
      </VFadeTransition>
    </VMain>
    <AppSignInRegisterDialog v-model="signInRegisterDialogIsOpen" />
    <VFadeTransition>
      <VOverlay v-if="requesting || isUnderMaintenance" z-index="2147483647">
        <VProgressCircular v-if="requesting" indeterminate size="64" />
        <span v-else class="text-h3">這個服務當前正在維護…</span>
      </VOverlay>
    </VFadeTransition>
  </VApp>
</template>

<script>
import Vue from 'vue';

import AppAboutDialog from '@/components/App/AppAboutDialog.vue';
import AppSignInRegisterDialog from '@/components/App/AppSignInRegisterDialog.vue';

import { delay } from '@/utils';

export default Vue.extend({
  name: 'App',
  components: { AppAboutDialog, AppSignInRegisterDialog },
  data: () => ({
    hdMenuItems: [
      // {
      //   title: '透析醫囑',
      //   name: 'hd-record-medical-orders',
      // },
      {
        title: '護理紀錄',
        name: 'hd-record-nursing-record',
      },
      {
        title: '透析中給藥',
        name: 'hd-record-dosing-in-hd',
      },
      {
        title: '特殊交班',
        name: 'hd-record-special-shift',
      },
    ],
    hdMenuItems1: [
      {
        title: '護理指導紀錄單',
        name: 'hd-assessment-nursing-guidance-record',
      },
      {
        title: '血管通路OP、PTA',
        name: 'hd-assessment-vascular-access-op-pta',
      },
    ],
    menuBarItems: [
      {
        title: '病患排程',
        name: 'patient-scheduling',
        items: [
          {
            title: '第一血液透析室',
            for: 'first',
          },
          {
            title: '第二血液透析室',
            for: 'second',
          },
          {
            title: '住院病房',
            for: 'hospitalized',
          },
        ],
      },
      {
        title: '我的病患',
        name: 'my-patients',
        items: [
          {
            title: '當班病患',
            for: 'onduty',
          },
          {
            title: '主護病患',
            for: 'mainly',
          },
        ],
      },
      // {
      //   title: '透析紀錄（展示用）',
      //   name: 'record',
      // },
      {
        title: '基本資料',
        name: 'blank',
      },
      {
        title: '批價系統',
        name: 'blank',
      },
      {
        title: '透析公告',
        name: 'blank',
      },
      {
        title: '同意書',
        name: 'blank',
      },
      {
        title: '透析管理作業',
        name: 'blank',
      },
    ],
    navDrawerIsOpen: false,
    appMenuIsOpen: false,
    aboutDialogIsOpen: false,
    signInRegisterDialogIsOpen: false,
    requesting: false,
  }),
  computed: {
    isUnderMaintenance() {
      return (
        this.$store.state.globalOptions?.isUnderMaintenance &&
        process.env.NODE_ENV !== 'development'
      );
    },
    isSigned() {
      return (
        !this.isUnderMaintenance && !this.requesting && _.isPlainObject(this.$store.state.profile)
      );
    },
    photoURL() {
      return this.getAvatarURL(40);
    },
    lazyPhotoURL() {
      return this.getAvatarURL(8);
    },
  },
  async created() {
    this.requesting = true;
    await delay(1000);
    const profile = {
      name: '測試帳號',
      email: 'dev@apulse.ai',
      photoURL: 'http://www.gravatar.com/avatar/d80aa71289ada84869e1c5eba63d68c5?d=identicon&r=g',
    };
    this.$store.commit('sign', profile);
    this.signInRegisterDialogIsOpen = !_.isPlainObject(this.$store.state.profile);
    this.requesting = false;
  },
  methods: {
    getAvatarURL(size) {
      const sizeParam = `s=${size * window.devicePixelRatio}`;
      if (!this.isSigned) return `https://www.gravatar.com/avatar/?d=mp&${sizeParam}`;
      const { photoURL } = this.$store.state.profile;
      if (photoURL.match(/^(https?:\/\/)www\.gravatar\.com\/avatar\//i))
        return `${photoURL}&${sizeParam}`;
      return photoURL;
    },
    async signOut() {
      this.requesting = true;
      await delay(1000);
      this.$store.commit('sign', null);
      this.signInRegisterDialogIsOpen = true;
      this.requesting = false;
    },
  },
});
</script>
