import VueRouter, { RouteConfig } from 'vue-router';
import Vue from 'vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: { name: 'patient-scheduling', params: { for: 'first' } },
  },
  {
    path: '/patient-scheduling/:for',
    name: 'patient-scheduling',
    component: () =>
      import(/* webpackChunkName: "patient-scheduling" */ '@/views/PatientScheduling.vue'),
  },
  {
    path: '/my-patients/:for',
    name: 'my-patients',
    component: () => import(/* webpackChunkName: "my-patients" */ '@/views/MyPatients.vue'),
  },
  {
    path: '/hd-record/:patient',
    name: 'hd-record',
    component: () => import(/* webpackChunkName: "hd-record" */ '@/views/HDRecord.vue'),
    children: [
      // {
      //   path: 'medical-orders',
      //   name: 'hd-record-medical-orders',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "hd-record-medical-orders" */ '@/views/HDRecordMedicalOrders'
      //     ),
      // },
      {
        path: 'nursing-record',
        name: 'hd-record-nursing-record',
        component: () =>
          import(
            /* webpackChunkName: "hd-record-nursing-record" */ '@/views/HDRecordNursingRecord.vue'
          ),
      },
      {
        path: 'dosing-in-hd',
        name: 'hd-record-dosing-in-hd',
        component: () =>
          import(/* webpackChunkName: "hd-record-dosing-in-hd" */ '@/views/HDRecordDosingInHD.vue'),
      },
      {
        path: 'special-shift',
        name: 'hd-record-special-shift',
        component: () =>
          import(
            /* webpackChunkName: "hd-record-special-shift" */ '@/views/HDRecordSpecialShift.vue'
          ),
      },
    ],
  },
  {
    path: '/hd-assessment/:patient',
    name: 'hd-assessment',
    component: () => import(/* webpackChunkName: "hd-assessment" */ '@/views/HDAssessment.vue'),
    children: [
      {
        path: 'nursing-guidance-record',
        name: 'hd-assessment-nursing-guidance-record',
        component: () =>
          import(
            /* webpackChunkName: "hd-assessment-nursing-guidance-record" */
            '@/views/HDAssessmentNursingGuidanceRecord.vue'
          ),
      },
      {
        path: 'vascular-access-op-pta',
        name: 'hd-assessment-vascular-access-op-pta',
        component: () =>
          import(
            /* webpackChunkName: "hd-assessment-vascular-access-op-pta" */
            '@/views/HDAssessmentVascularAccessOPpta.vue'
          ),
      },
    ],
  },
  {
    path: '/blank',
    name: 'blank',
    component: () => import(/* webpackChunkName: "blank" */ '@/views/Blank.vue'),
  },
];

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
