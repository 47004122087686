import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.VUE_APP_API_PATH || 'http://localhost:8000'}/api`,
  // withCredentials: true,
});

api.interceptors.response.use(
  (response) => response,
  (error) => error.response,
);

export default api;
