<template>
  <VDialog
    class="app-sign-in-register-dialog"
    :value="value"
    persistent
    max-width="480px"
    @input="$emit('input', $event)"
  >
    <VCard>
      <VToolbar color="primary" dark dense>
        <VBtn
          icon
          :disabled="window === 'signIn'"
          @click="
            window = 'signIn';
            signInData.password = '';
          "
        >
          <VIcon>mdi-arrow-left</VIcon>
        </VBtn>
        <VIcon left>mdi-{{ window === 'signIn' ? 'login-variant' : 'account-plus' }}</VIcon>
        <VToolbarTitle>{{ window === 'signIn' ? '登入' : '註冊' }}</VToolbarTitle>
        <VSpacer />
        <VMenu offset-y transition="slide-y-transition">
          <template #activator="{ attrs, on }">
            <VBtn text v-bind="attrs" v-on="on">
              <VIcon left>mdi-translate</VIcon>
              <span class="subtitle-1 text-capitalize">{{ currentLanguageName }}</span>
              <VIcon right>mdi-menu-down</VIcon>
            </VBtn>
          </template>
          <VList dense>
            <VListItem
              v-for="(language, i) in languages"
              :key="`languages-${i}`"
              @click="(currentLanguageName = language.name), language.apply()"
            >
              <VListItemTitle>{{ language.name }}</VListItemTitle>
            </VListItem>
          </VList>
        </VMenu>
        <VBtn icon @click="$vuetify.theme.dark = !$vuetify.theme.dark">
          <VIcon>mdi-{{ $vuetify.theme.dark ? 'weather-night' : 'white-balance-sunny' }}</VIcon>
        </VBtn>
      </VToolbar>
      <VWindow v-model="window" touchless>
        <VWindowItem
          v-for="windowItem in ['signIn', 'register']"
          :key="`window-${windowItem}`"
          :value="windowItem"
        >
          <VContainer>
            <VForm
              :id="`${windowItem}Form`"
              v-model="areFormsValid[windowItem]"
              :disabled="requesting"
              @submit.prevent
            >
              <VContainer>
                <VRow>
                  <VCol>
                    <VTextField
                      id="email"
                      v-model="signInData.email"
                      clearable
                      dense
                      label="電子信箱"
                      name="email"
                      prepend-icon="mdi-email"
                      type="email"
                      :rules="[inputRules.required, inputRules.email]"
                      @input="localStorage.setItem('signIn.email', $event)"
                    />
                  </VCol>
                </VRow>
                <VRow>
                  <VCol>
                    <VTextField
                      v-model="signInData.password"
                      clearable
                      dense
                      counter
                      label="密碼"
                      prepend-icon="mdi-lock"
                      :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                      :rules="[inputRules.required, inputRules.password]"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                    />
                  </VCol>
                </VRow>
              </VContainer>
              <template v-if="windowItem === 'register'">
                <VDivider />
                <VContainer>
                  <VRow>
                    <VCol>
                      <VTextField
                        id="name"
                        v-model="registerData.name"
                        clearable
                        dense
                        label="姓名"
                        name="name"
                        prepend-icon="mdi-account"
                        :rules="[inputRules.required]"
                      />
                    </VCol>
                  </VRow>
                  <VRow>
                    <VCol>
                      <VTextField
                        id="photoURL"
                        v-model="registerData.photoURL"
                        clearable
                        dense
                        type="url"
                        label="照片網址（選填）"
                        name="photoURL"
                        prepend-icon="mdi-account-circle"
                        :rules="[inputRules.url]"
                      />
                    </VCol>
                  </VRow>
                </VContainer>
              </template>
            </VForm>
          </VContainer>
        </VWindowItem>
      </VWindow>
      <VDivider />
      <VCardActions>
        <VBtn
          v-if="window === 'signIn'"
          color="warning"
          depressed
          :disabled="requesting"
          @click="
            signInData.password = '';
            window = 'register';
          "
        >
          註冊
        </VBtn>
        <VSpacer />
        <VCheckbox
          v-model="rememberMe"
          class="px-2 py-0 ma-0"
          hide-details
          dense
          :disabled="requesting"
          @click="localStorage.setItem('signIn.rememberMe', rememberMe)"
        >
          <template #label>
            <span style="line-height: normal" class="ml-n1 text-button">記住我</span>
          </template>
        </VCheckbox>
        <VBtn
          depressed
          type="submit"
          :form="`${window}Form`"
          color="primary"
          :loading="requesting"
          :disabled="requesting || !areFormsValid[window]"
          @click="window === 'signIn' ? signIn() : register()"
        >
          {{ window === 'signIn' ? '登入' : '註冊並登入' }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script type="module">
import Vue from 'vue';

import { blake3, md5 } from 'hash-wasm';

import * as inputRules from '@/utils/inputRules';

import * as user from '@/services/user';

export default Vue.extend({
  name: 'AppSignInRegisterDialog',
  props: {
    value: { type: Boolean, default: true },
  },
  data: () => ({
    inputRules,
    // TODO: make localStorage as Vue plugin https://bit.ly/2DsS4ES
    localStorage: window.localStorage,
    currentLanguageName: '繁體中文',
    languages: [
      {
        name: 'English',
        apply() {
          document.querySelector('html')?.setAttribute('lang', 'en');
        },
      },
      {
        name: '繁體中文',
        apply() {
          document.querySelector('html')?.setAttribute('lang', 'zh-Hant');
        },
      },
      {
        name: '简体中文',
        apply() {
          document.querySelector('html')?.setAttribute('lang', 'zh-Hans');
        },
      },
      {
        name: '日本語',
        apply() {
          document.querySelector('html')?.setAttribute('lang', 'ja');
        },
      },
      {
        name: '한국어',
        apply() {
          document.querySelector('html')?.setAttribute('lang', 'ko');
        },
      },
    ],

    window: 'signIn',
    signInData: {
      email: null,
      password: null,
    },
    registerData: {
      name: null,
      photoURL: null,
    },
    areFormsValid: {
      signIn: false,
      register: false,
    },
    rememberMe: false,
    showPassword: false,
    requesting: false,
  }),
  created() {
    this.rememberMe = JSON.parse(localStorage.getItem('signIn.rememberMe')) === true;
    this.signInData.email = localStorage.getItem('signIn.email') ?? '';
  },
  methods: {
    async getGravatarURL(email) {
      const { protocol } = window.location;
      const hashed = await md5(email.trim().toLowerCase());
      return `${protocol}//www.gravatar.com/avatar/${hashed}?d=identicon&r=g`;
    },
    async preprocessData() {
      this.signInData.email = this.signInData.email.trim();
      this.signInData.password = await blake3(this.signInData.password);
      this.registerData.name = this.registerData.name?.trim();
      this.registerData.photoURL = this.registerData.photoURL?.trim();
      if (_.isNil(this.registerData.photoURL) || this.registerData.photoURL === '')
        this.registerData.photoURL = await this.getGravatarURL(this.signInData.email);
    },
    async signIn() {
      this.requesting = true;
      const { showPassword } = this;
      this.showPassword = false;
      await this.preprocessData();
      try {
        const response = await user.signIn(this.signInData);
        const profile = {
          name: response.data.user.fullName,
          email: response.data.user.email,
          photoURL: response.data.user.photoURL,
        };
        sessionStorage.setItem('accessToken', response.data.token);
        this.$store.commit('sign', profile);
        this.$emit('input', false);
      } catch (error) {
        // TODO: show error message
      }
      this.signInData.password = '';
      this.showPassword = showPassword;
      this.requesting = false;
    },
    async register() {
      this.requesting = true;
      const { showPassword } = this;
      this.showPassword = false;
      await this.preprocessData();
      try {
        const request = {
          email: this.signInData.email,
          password: this.signInData.password,
          fullName: this.registerData.name,
          photoURL: this.registerData.photoURL,
        };
        const response = await user.register(request);
        const profile = {
          name: response.data.user.fullName,
          email: response.data.user.email,
          photoURL: response.data.user.photoURL,
        };
        sessionStorage.setItem('accessToken', response.data.token);
        this.$store.commit('sign', profile);
        this.$emit('input', false);
      } catch (error) {
        // TODO: show error message
      }
      this.signInData.password = '';
      this.showPassword = showPassword;
      this.requesting = false;
    },
  },
});
</script>
