var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VApp',[_c('VAppBar',{attrs:{"app":"","color":"primary","dark":"","dense":"","flat":_vm.isSigned},scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('VImg',{staticClass:"ma-2",attrs:{"src":require('@/assets/images/logo.png'),"contain":"","width":"332px","height":"80px"}})]},proxy:true},{key:"extension",fn:function(){return [_c('VSlideGroup',{staticStyle:{"padding-left":"332px"},attrs:{"multiple":"","show-arrows":""}},[(
            _vm._.isString(_vm.$route.name) &&
            !_vm.menuBarItems.map(function (v) { return v.name === _vm.$route.name; }).includes(true)
          )?[_c('VSlideItem',[(_vm.$route.name.startsWith('hd-record-'))?_c('VMenu',{attrs:{"open-on-hover":"","offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticStyle:{"margin":"0 3px"},attrs:{"active-class":"primary darken-3","input-value":true,"text":"","rounded":""}},'VBtn',attrs,false),on),[_vm._v(" 透析紀錄 ")])]}}],null,false,1261726646)},[_c('VList',{attrs:{"dense":""}},_vm._l((_vm.hdMenuItems),function(item,j){return _c('VListItem',{key:j,attrs:{"to":{ name: item.name, params: _vm.$route.params, query: _vm.$route.query }}},[_c('VListItemTitle',[_vm._v(_vm._s(item.title))])],1)}),1)],1):(_vm.$route.name.startsWith('hd-assessment-'))?_c('VMenu',{attrs:{"open-on-hover":"","offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticStyle:{"margin":"0 3px"},attrs:{"active-class":"primary darken-3","input-value":true,"text":"","rounded":""}},'VBtn',attrs,false),on),[_vm._v(" 透析評估表單 ")])]}}],null,false,437739057)},[_c('VList',{attrs:{"dense":""}},_vm._l((_vm.hdMenuItems1),function(item,j){return _c('VListItem',{key:j,attrs:{"to":{ name: item.name, params: _vm.$route.params, query: _vm.$route.query }}},[_c('VListItemTitle',[_vm._v(_vm._s(item.title))])],1)}),1)],1):_vm._e()],1),_c('VDivider',{attrs:{"vertical":"","light":""}})]:_vm._e(),_vm._l((_vm.menuBarItems),function(item,i){return _c('VSlideItem',{key:i},[('items' in item)?_c('VMenu',{attrs:{"open-on-hover":"","offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticStyle:{"margin":"0 3px"},attrs:{"active-class":"primary darken-3","input-value":_vm.$route.name === item.name,"text":"","rounded":""}},'VBtn',attrs,false),on),[_vm._v(" "+_vm._s(item.title)+" ")])]}}],null,true)},[_c('VList',{attrs:{"dense":""}},_vm._l((item.items),function(subItem,j){return _c('VListItem',{key:j,attrs:{"to":{ name: item.name, params: { for: subItem.for } }}},[_c('VListItemTitle',[_vm._v(_vm._s(subItem.title))])],1)}),1)],1):_c('VBtn',{staticStyle:{"margin":"0 3px"},attrs:{"active-class":"primary darken-3","text":"","rounded":"","to":{ name: item.name }}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)})],2)]},proxy:true}])},[_c('VSpacer',{staticStyle:{"padding-left":"332px"}}),_c('VToolbarTitle',{staticClass:"mt-auto",staticStyle:{"font-size":"28px"}},[_vm._v(" "+_vm._s(_vm.$store.state.app.name)+" ")]),_c('VSpacer'),_c('VMenu',{attrs:{"max-width":"240px","offset-y":"","transition":"slide-y-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"ml-1 pa-1",staticStyle:{"border-radius":"24px"},attrs:{"text":"","min-width":"0","height":"48"}},'VBtn',attrs,false),on),[_c('VAvatar',{attrs:{"size":"40","color":"#c5c5c5"}},[_c('VImg',{attrs:{"src":_vm.photoURL,"lazy-src":_vm.lazyPhotoURL}})],1),_c('span',{staticClass:"mx-1 font-weight-regular text-none",staticStyle:{"font-size":"1.125rem"}},[_vm._v(" "+_vm._s(_vm.isSigned ? _vm.$store.state.profile.name : '未登入')+" ")])],1)]}}])},[(_vm.isSigned)?_c('VCard',[_c('VList',[_c('VListItem',[_c('VListItemAvatar',{attrs:{"color":"#c5c5c5"}},[_c('VImg',{attrs:{"src":_vm.photoURL,"lazy-src":_vm.lazyPhotoURL}})],1),_c('VListItemContent',[_c('VListItemTitle',[_vm._v(_vm._s(_vm.$store.state.profile.name))]),_c('VListItemSubtitle',[_vm._v(_vm._s(_vm.$store.state.profile.email))])],1),_c('VListItemAction',[_c('VBtn',{attrs:{"icon":""}},[_c('VIcon',[_vm._v("mdi-square-edit-outline")])],1)],1)],1)],1),_c('VDivider'),_c('VCardActions',[_c('VBtn',{attrs:{"color":"pink","outlined":"","text":"","block":""},on:{"click":function($event){return _vm.signOut()}}},[_c('VIcon',{attrs:{"left":""}},[_vm._v("mdi-logout-variant")]),_vm._v(" 登出 ")],1)],1)],1):_vm._e()],1),_c('VMenu',{attrs:{"left":"","offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"icon":""}},'VBtn',attrs,false),on),[_c('VIcon',[_vm._v("mdi-dots-vertical")])],1)]}}]),model:{value:(_vm.appMenuIsOpen),callback:function ($$v) {_vm.appMenuIsOpen=$$v},expression:"appMenuIsOpen"}},[_c('VList',{attrs:{"dense":""}},[_c('VListItem',{on:{"click":function($event){_vm.$vuetify.theme.dark = !_vm.$vuetify.theme.dark}}},[_c('VListItemIcon',[_c('VIcon',[_vm._v("mdi-"+_vm._s(_vm.$vuetify.theme.dark ? 'weather-night' : 'white-balance-sunny'))])],1),_c('VListItemTitle',[_vm._v("主題："+_vm._s(("" + (_vm.$vuetify.theme.dark ? '深色' : '淺色'))))])],1),_c('AppAboutDialog',{on:{"input":function($event){$event ? null : (_vm.appMenuIsOpen = false)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('VListItem',_vm._g(_vm._b({},'VListItem',attrs,false),on),[_c('VListItemIcon',[_c('VIcon',[_vm._v("mdi-information")])],1),_c('VListItemTitle',[_vm._v("關於")])],1)]}}]),model:{value:(_vm.aboutDialogIsOpen),callback:function ($$v) {_vm.aboutDialogIsOpen=$$v},expression:"aboutDialogIsOpen"}})],1)],1)],1),_c('VMain',[_c('VFadeTransition',{attrs:{"mode":"out-in"}},[(_vm.isSigned)?_c('RouterView',{staticStyle:{"max-height":"calc(100vh - 96px)"}}):_vm._e()],1)],1),_c('AppSignInRegisterDialog',{model:{value:(_vm.signInRegisterDialogIsOpen),callback:function ($$v) {_vm.signInRegisterDialogIsOpen=$$v},expression:"signInRegisterDialogIsOpen"}}),_c('VFadeTransition',[(_vm.requesting || _vm.isUnderMaintenance)?_c('VOverlay',{attrs:{"z-index":"2147483647"}},[(_vm.requesting)?_c('VProgressCircular',{attrs:{"indeterminate":"","size":"64"}}):_c('span',{staticClass:"text-h3"},[_vm._v("這個服務當前正在維護…")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }