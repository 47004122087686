import { AxiosResponse } from 'axios';

import api from '@/services/api';

const url = {
  register: '/users',
  signIn: '/users/signin',
  getCurrent: '/user',
};

interface RegisterRequest {
  email: string;
  password: string;
  fullName: string;
  photoURL: string;
}

interface SignInRequest {
  email: string;
  password: string;
}

interface UserResponse {
  user: {
    email: string;
    fullName: string;
    photoURL: string;
  };
  token: string;
}

export function register(request: RegisterRequest): Promise<AxiosResponse<UserResponse>> {
  return api.post(url.register, request);
}

export function signIn(request: SignInRequest): Promise<AxiosResponse<UserResponse>> {
  return api.post(url.signIn, request);
}

export function getCurrent(): Promise<AxiosResponse<UserResponse>> {
  const token = sessionStorage.getItem('accessToken');
  return api.get(url.getCurrent, { headers: { Authorization: `Bearer ${token}` } });
}
