import Vue from 'vue';

import { LangLocales } from 'vuetify/types/services/lang.d';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

import '@mdi/font/css/materialdesignicons.css';

import i18n from '@/plugins/i18n';

Vue.use(Vuetify);

const locales = i18n.messages as unknown as LangLocales;

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: 'xs',
    scrollBarWidth: 8,
  },
  lang: {
    current: 'zh-Hant',
    locales,
  },
  theme: {
    // default: 'light',
    dark: false, // window.matchMedia('(prefers-color-scheme: dark)').matches,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#44b272', // colors.blue.darken2,
        secondary: '#337ab7', // colors.grey.darken2,
        accent: colors.blue.accent1,
        error: colors.red.accent2,
        info: colors.blue.base,
        success: colors.green.base,
        warning: colors.orange.darken1,
      },
      dark: {
        primary: '#008146', // colors.blue.base,
        secondary: '#004e87', // colors.grey.darken2,
        accent: colors.pink.accent2,
        error: colors.red.accent2,
        info: colors.blue.base,
        success: colors.green.base,
        warning: colors.orange.darken1,
      },
    },
  },
});
