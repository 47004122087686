<template>
  <VTooltip bottom :disabled="readonly || ruleMessage === true">
    <template #activator="{ on, attrs }">
      <VCombobox
        dense
        hide-details
        :type="type"
        :class="classString"
        :label="label"
        :value="value"
        :rules="clearable ? undefined : rules"
        :clearable="clearable"
        :outlined="outlined"
        :readonly="readonly"
        :disabled="disabled"
        :menu-props="{ offsetY: true, transition: 'slide-y-transition' }"
        :multiple="multiple"
        :small-chips="multiple"
        :items="items"
        v-bind="attrs"
        v-on="on"
        @input="$emit('input', $event)"
      />
    </template>
    {{ ruleMessage }}
  </VTooltip>
</template>

<script>
import Vue from 'vue';

import { required, test } from '@/utils/inputRules';

function isInputValueValid(value) {
  return _.isNil(value) || _.isString(value) || _.isNumber(value) || _.isArray(value);
}

export default Vue.extend({
  name: 'XCombobox',
  props: {
    type: { type: String, default: 'text' },
    value: { validator: isInputValueValid, required: true },
    label: { type: String, default: '' },
    items: { type: Array, required: true },
    rules: { type: Array, default: () => [required] },
    clearable: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
  },
  computed: {
    classString() {
      const labelClass = this.outlined || this.label.trim().length > 0 ? '' : 'v-input--hide-label';
      const alignClass = (() => {
        if (this.type === 'text' && this.readonly) return 'text-center';
        if (this.type === 'number') return 'text-right';
        return 'text-left';
      })();
      return `${labelClass} ${alignClass}`;
    },
    ruleMessage() {
      return test(this.rules, this.value);
    },
  },
});
</script>
