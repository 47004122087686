import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

interface Profile {
  email: string;
  name: string;
  photoURL: string;
}

interface Author {
  name: string;
  email: string;
  url: string;
}

interface State {
  app: {
    name: string;
    icon: string;
    themeColor: string;
    package: {
      version: string;
      author: Author;
      contributors: Author[];
    };
  };
  globalOptions: {
    isUnderMaintenance: boolean;
  };
  profile: Profile | null;

  fe: { [key: string]: number }[];
}

const state: State = {
  app: {
    name: process.env.VUE_APP_NAME as string,
    icon: process.env.VUE_APP_ICON as string,
    themeColor: process.env.VUE_APP_THEME_COLOR as string,
    package: {
      version: process.env.VUE_APP_PACKAGE_VERSION as string,
      author: process.env.VUE_APP_PACKAGE_AUTHOR as unknown as Author,
      contributors: process.env.VUE_APP_PACKAGE_CONTRIBUTORS as unknown as Author[],
    },
  },
  globalOptions: {
    isUnderMaintenance: false,
  },
  profile: null,

  fe: [],
};

export default new Vuex.Store({
  state,
  mutations: {
    sign(state, profile: Profile) {
      state.profile = _.isPlainObject(profile) ? profile : null;
    },
    fe(state, object) {
      state.fe.push(object);
    },
  },
  actions: {},
  modules: {},
});
