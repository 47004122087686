<template>
  <VTooltip bottom :disabled="readonly || ruleMessage === true">
    <template #activator="{ on, attrs }">
      <VTextField
        dense
        hide-details
        :type="type"
        :class="classString"
        :label="label"
        :suffix="suffix"
        :value="value"
        :rules="rules"
        :outlined="outlined"
        :readonly="readonly"
        :disabled="disabled"
        v-bind="attrs"
        v-on="on"
        @input="$emit('input', $event)"
      />
    </template>
    {{ ruleMessage }}
  </VTooltip>
</template>

<script>
import Vue from 'vue';

import { required, test } from '@/utils/inputRules';

export default Vue.extend({
  name: 'XTextField',
  props: {
    type: { type: String, default: 'text' },
    value: { validator: (v) => _.isNil(v) || _.isString(v), required: true },
    label: { type: String, default: '' },
    suffix: { type: String, default: '' },
    rules: { type: Array, default: () => [required] },
    clearable: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    classString() {
      const labelClass = this.outlined || this.label.trim().length > 0 ? '' : 'v-input--hide-label';
      const alignClass = (() => {
        if (this.type === 'text' && this.readonly) return 'text-center';
        if (this.type === 'number') return 'text-right';
        return 'text-left';
      })();
      return `${labelClass} ${alignClass}`;
    },
    ruleMessage() {
      return test(this.rules, this.value);
    },
  },
});
</script>
