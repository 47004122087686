import VueI18n, { LocaleMessages } from 'vue-i18n';
import Vue from 'vue';

// @ts-expect-error: no type
import * as vuetifyLocales from 'vuetify/lib/locale';

import { camelCase } from 'change-case';

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = { ...locales(key), ...vuetifyLocales[camelCase(locale)] };
    }
  });
  return messages;
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE ?? 'zh-Hant',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE ?? 'en',
  messages: loadLocaleMessages(),
});
