type StringValue = string | null | undefined;
type NumberValue = number | null | undefined;
type Value = StringValue | NumberValue | Array<unknown> | { [key: string]: unknown };
type CheckedResult = string | true;

export function isNonEmptyString(value: StringValue): value is string {
  return _.isString(value) && value.trim().length > 0;
}

function isPlainObject(value: Value): value is { [key: string]: unknown } {
  return _.isPlainObject(value);
}

export function test(rules: Array<(value: Value) => CheckedResult>, value: Value): CheckedResult {
  return rules.reduce(
    (message, rule) => (message === true ? rule(value) : message),
    true as CheckedResult,
  );
}

export function required(value: Value): CheckedResult {
  return isPlainObject(value) || _.isArray(value) || _.isNumber(value) || isNonEmptyString(value)
    ? true
    : '必須的';
}

export function email(value: StringValue): CheckedResult {
  if (!isNonEmptyString(value)) return true;
  // Reference: https://bit.ly/2OaHDLK
  const pattern =
    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{' +
    '1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';
  if (!new RegExp(pattern).test(value.trim())) return '無效的電子信箱格式';
  return true;
}

export function password(value: StringValue): CheckedResult {
  if (_.isString(value)) {
    if (!/^[\x20-\x7e]+$/.test(value)) return '只能使用0~9、A~Z、a~z和常見的標點符號';
    if (value.length < 6) return '使用6字元或以上當作密碼';
  }
  return true;
}

export function url(value: StringValue): CheckedResult {
  if (!isNonEmptyString(value)) return true;
  // Reference: https://bit.ly/3svZivX
  const pattern =
    '^(?:(?:https?|ftp):\\/\\/)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
    '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\' +
    '.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d' +
    '|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\u' +
    'ffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u0' +
    '0a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))(?::\\d{2,5})?(?:[/?#]\\S*)?$';
  if (!new RegExp(pattern).test(value.trim())) return '無效的網址格式';
  return true;
}

export function filename(value: StringValue): CheckedResult {
  if (!isNonEmptyString(value)) return true;
  // Reference: https://stackoverflow.com/a/53635003
  if (/^(con|prn|aux|nul|((com|lpt)[0-9]))$|(["*/:<>?\\|])|(\.|\s)$/i.test(value.trim()))
    return '無效的檔案名稱';
  return true;
}

export function idNumber(value: StringValue): CheckedResult {
  if (!isNonEmptyString(value)) return true;
  const letterToN = (letter: string): string => {
    const ascii = letter.charCodeAt(0);
    if (ascii >= 'A'.charCodeAt(0) && ascii < 'I'.charCodeAt(0)) return `${ascii - 55}`;
    if (ascii >= 'J'.charCodeAt(0) && ascii < 'O'.charCodeAt(0)) return `${ascii - 56}`;
    if (ascii >= 'P'.charCodeAt(0) && ascii < 'W'.charCodeAt(0)) return `${ascii - 57}`;
    if (ascii >= 'X'.charCodeAt(0) && ascii < 'Z'.charCodeAt(0)) return `${ascii - 58}`;
    return { I: 34, O: 35, W: 32, Z: 33 }[
      String.fromCharCode(ascii) as 'I' | 'O' | 'W' | 'Z'
    ].toString();
  };
  const idNumberToNs = (v: string): string | null => {
    // 身分證號、新式居留證號
    if (/^[A-Z][1289]\d{8}$/.test(v)) return letterToN(v[0]) + v.substring(1);
    // 舊式居留證號
    if (/^[A-Z][A-D]\d{8}$/.test(v)) return letterToN(v[0]) + letterToN(v[1])[1] + v.substring(2);
    return null;
  };
  const nA = idNumberToNs(value)
    ?.split('')
    .map((c) => parseInt(c, 10));
  if (_.isArray(nA)) {
    const kA = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1, 1];
    if (_.sum(nA.map((n, i) => n * kA[i])) % 10 === 0) return true;
  }
  return '無效的身分證或居留證號';
}

export function numberString(
  value: string,
  precision: 0 | 1 | 2 | 3 | 4,
  range?: [number, number],
): CheckedResult {
  if (!isNonEmptyString(value)) return true;
  const trimedValue = value.trim();
  if (!/^-?([1-9]\d+|\d)(\.\d+)?$/.test(trimedValue)) return '無效的數字格式';
  const isNegative = trimedValue[0] === '-';
  const valueWithoutSign = trimedValue.substring(isNegative ? 1 : 0);
  const [integer, fraction] = valueWithoutSign.split('.') as [string, string | null];
  const integerNumber = parseInt(integer, 10);
  if (isNegative && integerNumber === 0 && (_.isNil(fraction) || parseInt(fraction, 10) === 0))
    return '負數不能為零';
  if (
    (precision > 0 && (_.isNil(fraction) || precision !== fraction.length)) ||
    (precision === 0 && !_.isNil(fraction))
  )
    return '無效的小數位數';
  if (_.isArray(range) && range.length === 2) {
    const number = parseFloat(trimedValue);
    if (number < range[0] || number > range[1]) return `數字需在${range[0]}到${range[1]}之間`;
  }
  return true;
}
