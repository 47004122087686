import Vue from 'vue';

import '@/plugins/dayjs';
import '@/plugins/lodash';
import '@/registerServiceWorker';
import App from '@/App.vue';
import i18n from '@/plugins/i18n';
import router from '@/plugins/router';
import store from '@/plugins/store';
import vuetify from '@/plugins/vuetify';

import '@/styles/global.scss';

import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
// @ts-expect-error: no type
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';

import '@/components/Global';

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

Vue.config.productionTip = false;

// eslint-disable-next-line vue/require-name-property
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
