/* eslint-disable import/prefer-default-export */

export function delay(ns: number): Promise<void> {
  return new Promise((r) => setTimeout(r, ns));
}

// TODO
export function numberOrNull(value: unknown): number | null {
  return Number.isFinite(value) ? (value as number) : null;
}
