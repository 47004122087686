import Vue from 'vue';

import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import 'dayjs/locale/ko';
import 'dayjs/locale/zh';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-hk';
import 'dayjs/locale/zh-tw';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

const DayJS = {
  install(VueInstance: typeof Vue, options?: { locale: string }) {
    if (!_.isNil(options)) dayjs.locale(options.locale);
    dayjs.extend(localizedFormat);
    Object.defineProperties(Vue, {
      dayjs: {
        get() {
          return dayjs;
        },
      },
    });
    Object.defineProperties(VueInstance.prototype, {
      dayjs: {
        get() {
          return dayjs;
        },
      },
    });
  },
};

Vue.use(DayJS, { locale: 'zh-tw' });
